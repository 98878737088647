import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'compareCard',
    'compareCheckbox',
    'compareTitle',
    'compareIds',
    'favoriteCheckbox',
    'compareButton',
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCheckboxChecked(_e) {
    const checkIds = this.compareCheckboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
    const template = this.compareTitleTarget.dataset.text;
    const textInnerHtml = checkIds.length === 1 ? 'Applicant' : 'Applicants';
    this.compareTitleTarget.innerHTML = template.replace(
      '{{count}}',
      `${checkIds.length} ${textInnerHtml}`
    );
    this.compareCardTarget.style.display =
      checkIds.length > 0 ? 'flex' : 'none';
    this.compareIdsTarget.value = checkIds.join(',');

    if (checkIds.length >= 2) {
      this.compareButtonTarget.disabled = false;
    } else {
      this.compareButtonTarget.disabled = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCancel(_e) {
    this.compareCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.compareCardTarget.style.display = 'none';
  }

  onFavoriteCheckboxChecked(e) {
    const applicantId = e.target.value;
    console.log('onFavoriteCheckboxChecked', applicantId);
  }

  toggleVotingGuide() {
    this.element.classList.toggle('voting-guide-open');
  }

  handleCloseVotingGuide() {
    this.element.classList.remove('voting-guide-open');
  }
}
