import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['timeLeft', 'timeLeftContainer'];

  connect() {
    this.startCountdown();
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  startCountdown() {

    const endTime = new Date(
      this.timeLeftTarget.dataset.timeLeftValue
    ).getTime();

    this.interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      let timeString = '';
      if (days > 0) {
        timeString = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeString = `${hours}h ${minutes}m`;
      } else {
        timeString = `${minutes}m`;
      }

      if (days <= 15 && days > 7) {
        this.timeLeftContainerTarget.classList.add(
          'room-card--info--time-left--info'
        );
      } else if (days <= 7 && days > 1) {
        this.timeLeftContainerTarget.classList.add(
          'room-card--info--time-left--warning'
        );
      } else if (days <= 1 && distance > 0) {
        this.timeLeftContainerTarget.classList.add(
          'room-card--info--time-left--danger'
        );
      } else {
        this.timeLeftContainerTarget.classList.add('d-none');
      }

      this.timeLeftTarget.textContent = timeString;
    }, 1000);
  }
}
