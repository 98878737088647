import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.classList.add('show');
    document.querySelector('#turbo-notice').classList.remove('fadeIn');
  }

  handleClose() {
    this.element.remove();
  }
}
