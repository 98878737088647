import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const form = this.element.querySelector('form');
    form.addEventListener('submit', () => {
      this.element.remove();
    });
  }

  handleClose() {
    this.element.remove();
  }
}
