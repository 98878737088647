import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['image', 'initials'];

  connect() {
    this.loadImage();
  }

  loadImage() {
    const firstName = this.element.dataset.userFirstName;
    const lastName = this.element.dataset.userLastName;
    const initials = `${firstName[0]}${lastName[0]}`;
    this.initialsTarget.innerHTML = initials;
  }
}
