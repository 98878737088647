import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['viewVoting', 'hideVoting', 'activities'];

  static values = {
    contractId: String,
  };

  handleClose() {
    this.element.remove();
    const imagePreview = document.querySelector('#image_previewer');
    if (imagePreview) {
      imagePreview.innerHTML = '';
    }
  }

  findTargetByContractId(targetArray, contractId) {
    return targetArray.find(
      (target) => target.dataset.contractId === contractId
    );
  }

  toggleVotingVisibility(contractId, showActivities) {
    const viewButton = this.findTargetByContractId(
      this.viewVotingTargets,
      contractId
    );
    const hideButton = this.findTargetByContractId(
      this.hideVotingTargets,
      contractId
    );
    const activities = this.findTargetByContractId(
      this.activitiesTargets,
      contractId
    );

    viewButton.classList.toggle('d-none', showActivities);
    hideButton.classList.toggle('d-none', !showActivities);
    activities.classList.toggle('d-none', !showActivities);
  }

  viewVoting(e) {
    const { contractId } = e.currentTarget.dataset;
    this.toggleVotingVisibility(contractId, true);
  }

  hideVoting(e) {
    const { contractId } = e.currentTarget.dataset;
    this.toggleVotingVisibility(contractId, false);
  }
}
