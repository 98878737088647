import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap';

// eslint-disable-next-line no-undef
$('.toast').toast('show');

const initializeBootstrapComponents = () => {
  // Initialize toasts
  $('.toast').toast().toast('show');

  // Initialize tooltips
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"][data-tooltip-onload="true"]').tooltip('show');

  // Initialize popovers
  $('[data-toggle="popover"]').popover({
    sanitizeFn: (content) => content,
  });
  $('[data-toggle="popover"][data-popover-onload="true"]').popover('show');

  // Handle popover dismiss buttons
  $('[data-toggle="popover"]').on('shown.bs.popover', () => {
    $('.popover [data-dismiss="popover"]').click((e) => {
      $(e.target.closest('.popover')).popover('hide');
    });
  });

  // Initialize dropdowns
  $('[data-bs-toggle="dropdown"]').dropdown();
  $(document).on('click', () => {
    $('[data-bs-toggle="dropdown"]')
      .dropdown('hide')
      .attr('aria-expanded', false);
  });
};

window.addEventListener('turbolinks:load', initializeBootstrapComponents);

const styles = getComputedStyle(document.documentElement);

export default {
  primary: styles.getPropertyValue('--primary'),
  secondary: styles.getPropertyValue('--secondary'),
  success: styles.getPropertyValue('--success'),
  warning: styles.getPropertyValue('--warning'),
  danger: styles.getPropertyValue('--danger'),
  info: styles.getPropertyValue('--info'),
  light: styles.getPropertyValue('--light'),
  dark: styles.getPropertyValue('--dark'),
  white: styles.getPropertyValue('--white'),
  black: styles.getPropertyValue('--black'),
  gray: styles.getPropertyValue('--gray'),
  closePopoversAndTooltips: () => {
    // eslint-disable-next-line no-undef
    $('.popover').popover('hide');
    // eslint-disable-next-line no-undef
    $('.tooltip').tooltip('hide');
  },
};
